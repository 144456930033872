import React, { useContext, Fragment, useEffect } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SendIcon from "@material-ui/icons/Send";
import { withStyles } from "@material-ui/core/styles";
import { useComments } from "./useComments";
import { CommentItem } from "./CommentItem";
import { CommentDialogue } from "./CommentDialogue";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import GlobalUiContext from "contexts/globalUiContext";
import useStyles from "../styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
    marginBottom: 4,
  },
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "#4F98BC",
    },
  },
})((props) => (
  <Tabs
    orientation={"horizontal"}
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

const StyledTab = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(0),
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: "90px",
    textAlign: "center",
    alignItems: "center",
    "&. MuiTab-root": {
      minWidth: "90px",
    },
    "&:hover": {
      color: "#4F98BC",
      opacity: 1,
    },
    "&$selected": {
      color: "#4F98BC",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#4F98BC",
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      fontWeight: 600,
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

export function Comments({ setCommentCount }) {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { role } = globalUi;

  const {
    commentsList,
    handleChange,
    handleChangeTab,
    handleKeyDown,
    handleSubmitComment,
    isCommentsLoading,
    isMutationLoading,
    searched,
    value,
  } = useComments();

  useEffect(() => {
    setCommentCount(
      commentsList?.internal?.length + commentsList?.partner?.length || 0
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsList]);

  return (
    <Fragment>
      {role?.category !== "subcontractor" ? (
        <StyledTabs
          value={value}
          onChange={handleChangeTab}
          aria-label="styled tabs"
        >
          <StyledTab label="Internal" {...a11yProps(0)} />
          <StyledTab label="Partners" {...a11yProps(1)} />
        </StyledTabs>
      ) : null}
      {role?.category !== "subcontractor" ? (
        <>
          <TabPanel value={value} index={0}>
            {commentsList?.internal?.length > 0 && (
              <Box className={classes.commentsMainContainer}>
                {commentsList?.internal?.map((comment, index) => (
                  <CommentItem
                    key={index}
                    classes={classes}
                    comment={comment}
                    index={index}
                  />
                ))}
              </Box>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {commentsList?.partner?.length > 0 && (
              <Box className={classes.commentsMainContainer}>
                {commentsList?.partner?.map((comment, index) => (
                  <CommentItem
                    key={index}
                    classes={classes}
                    comment={comment}
                    index={index}
                  />
                ))}
              </Box>
            )}
          </TabPanel>
        </>
      ) : commentsList?.partner?.length > 0 ? (
        <Box className={classes.commentsMainContainer}>
          {commentsList?.partner?.map((comment, index) => (
            <CommentItem
              key={index}
              classes={classes}
              comment={comment}
              index={index}
            />
          ))}
        </Box>
      ) : null}
      {isMutationLoading || isCommentsLoading ? (
        <LoadingIndicator />
      ) : (
        <TextField
          value={searched}
          multiline={true}
          onChange={(event) => {
            handleChange(event);
          }}
          variant="standard"
          placeholder="Add a comment"
          onKeyDown={handleKeyDown}
          disabled={isMutationLoading}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSubmitComment}>
                  <SendIcon
                    style={{
                      color: searched.length > 0 ? "#4F98BC" : "#D9D9D9",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          className={classes.addCommentField}
        ></TextField>
      )}

      <CommentDialogue />
    </Fragment>
  );
}
