import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addNewPartnerComment,
  deletePartnerComment,
  getPartnerComments,
  updatePartnerComment,
} from "../../../services/partners/commentsService";
import { useCommentDialogue } from "../context/CommentDialogueContext";
import { useGetUserId } from "../context/UserIdContext";

export function useComments() {
  // Contexts
  const { userId } = useGetUserId();
  const [commentAction, dispatchCommentAction] = useCommentDialogue();
  const { isOpenAddDialog, isEditing, commentToEdit } = commentAction;

  const [comment, setComment] = useState("");
  const [searched, setSearched] = useState("");

  const [isMutationLoading, setIsMutationLoading] = useState(false);
  const [isRefetchingComments, setIsRefetchingComments] = useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [value, setValue] = React.useState(0);

  const {
    data: commentsList,
    isLoading: isCommentsLoading,
    refetch,
  } = useQuery(
    ["partnerComments", userId],
    async () => {
      const { data } = await getPartnerComments(userId);
      return data.data;
    },
    {
      enabled: !!userId,
    }
  );

  const dispatchEditComment = (comment) => {
    dispatchCommentAction({
      type: "TOGGLE_EDIT_COMMENT",
      comment,
      open: true,
      isEditing: true,
    });
  };

  const dispatchCloseDialog = () => {
    dispatchCommentAction({
      type: "TOGGLE_EDIT_COMMENT",
      open: false,
      isEditing: false,
      comment: null,
    });
  };

  useEffect(() => {
    if (commentToEdit) {
      setComment(commentToEdit.body);
    }
  }, [commentToEdit]);

  const addPartnerComment = useMutation(addNewPartnerComment, {
    onSuccess: () => {
      setIsRefetchingComments(true);
      refetch().finally(() => setIsRefetchingComments(false));
      setIsMutationLoading(false);
    },
    onError: () => {
      setIsMutationLoading(false);
    },
  });

  const deleteComment = useMutation(deletePartnerComment, {
    onSuccess: () => {
      setIsRefetchingComments(true);
      refetch().finally(() => setIsRefetchingComments(false));
      setIsMutationLoading(false);
    },
    onError: () => {
      setIsMutationLoading(false);
    },
  });

  const updateComment = useMutation(updatePartnerComment, {
    onSuccess: () => {
      setShowLoadingDialog(true);
      setIsRefetchingComments(true);
      refetch().finally(() => setIsRefetchingComments(false));
      dispatchCloseDialog();
      setIsMutationLoading(false);
    },
    onError: () => {
      setIsMutationLoading(false);
    },
  });

  const handleChange = (event) => {
    setSearched(event.target.value);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitComment();
    }
  };

  const handleSubmitComment = () => {
    if (!isEditing && searched.trim() === "") {
      console.warn("Comment field is empty.");
      return;
    }

    if (isEditing && !comment.trim()) {
      console.warn("Comment cannot be empty while editing.");
      return;
    }

    let tab = value === 0 ? "internal" : "partner";
    setIsMutationLoading(true);

    if (!isEditing) {
      addPartnerComment.mutate(
        {
          partner_id: userId,
          data: { body: searched, tab },
        },
        {
          onSuccess: () => {
            setSearched("");
            setIsMutationLoading(false);
          },
          onError: (error) => {
            console.error(
              "Failed to add comment:",
              error.response?.data || error
            );
            setIsMutationLoading(false);
          },
        }
      );
    } else {
      updateComment.mutate(
        {
          id: commentToEdit.id,
          partner_id: userId,
          data: { body: comment, tab },
        },
        {
          onSuccess: () => {
            setSearched("");
            setIsMutationLoading(false);
            dispatchCloseDialog();
          },
          onError: (error) => {
            console.error(
              "Failed to update comment:",
              error.response?.data || error
            );
            setIsMutationLoading(false);
          },
        }
      );
    }
  };

  return {
    commentsList,
    isCommentsLoading: isRefetchingComments || isCommentsLoading,
    addPartnerComment,
    deleteComment,
    dispatchEditComment,
    dispatchCloseDialog,
    isOpenAddDialog,
    isEditing,
    commentToEdit,
    comment,
    setComment,
    handleChange,
    handleSubmitComment,
    handleKeyDown,
    handleChangeTab,
    isMutationLoading,
    showLoadingDialog,
    searched,
    value,
    setValue,
    userId,
  };
}
