import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import WorkticketBreadcrum from "./workticketBreadcrum";
import WorkticketHeader from "./workticketHeader";
import WorkticketInformation from "./workticketInformation";
import WorkticketTask from "./workticketTask";
import WorkticketSchedule from "./workticketSchedule";
import WorkticketActivity from "./workticketActivity";
// import WorkticketFilePaper from "./workticketFilePaper";
// import WorkticketFile from "./workticketFile";
import WorkticketActions from "./workticketActions";
import WorkticketLocation from "./workticketLocation";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InfoIcon from "@material-ui/icons/Info";
import WorkIcon from "@material-ui/icons/Storage";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import { UploadFileProvider } from "contexts/uploadFileContext";
import { WorkticketUpload } from "./workticketUpload";
import { WorkticketGallery } from "./worticketGallery";
import { UploadFileLensProvider } from "contexts/uploadFileLensContext";

import {
  getWorkticketDetails,
  getWorkticketComment,
} from "services/workticketSurfaceService";
import { useWorkticketView } from "contexts/workticketViewContext";
import WorkticketRating from "./workticketRating";

import useStyles from "./styles";
import WorkticketLunaLens from "pages/workticketPage/workticketLunaLens";
import { getLunaLensCategories } from "services/lunaLensService";
import useLunaLensStore from "store/lunaLensAnalysis";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";

const WorkticketLayout = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;

  const [valueBottomNavigation, setBottomNavigation] = useState(
    props.id_project ? "info" : "work"
  );
  const [isMobile, setIsMobile] = useState(false);
  const [stateContext, dispatchContext] = useWorkticketView();
  const { workticket } = stateContext ?? null;

  const setCategoryList = useLunaLensStore((state) => state.setCategoryList);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    }
    window.addEventListener("resize", resize.bind(this));
  }, []);

  const resize = () => {
    setIsMobile(window.innerWidth <= 960);
  };

  useEffect(() => {
    const loadWorkticket = async () => {
      try {
        if (props.id !== "new") {
          const result = await getWorkticketDetails(props.id);
          dispatchContext({
            type: "SET_WORKTICKET",
            workticket: result.data.data.workticket,
          });

          dispatchContext({
            type: "SET_LOADING",
            isLoading: false,
          });
        } else {
          dispatchContext({
            type: "SET_LOADING",
            isLoading: true,
          });
          dispatchContext({
            type: "SET_WORKTICKET",
            workticket: "new",
          });
        }
      } catch (e) {
        console.log("Cannot load workticket data");
      }
    };
    const loadLunaLensCategories = async () => {
      if (
        hasPermission(permissionWorkticket.LUNA_LENS_VIEW, permissions) ||
        hasPermission(permissionWorkticket.LUNA_LENS_MANAGE, permissions)
      ) {
        const user = JSON.parse(localStorage.getItem("user"));
        const res = await getLunaLensCategories(user.id);
        setCategoryList(res.data.categories);
      }
    };
    loadWorkticket();
    loadLunaLensCategories();
  }, [props.id, dispatchContext, setCategoryList, permissions]);

  const handleNavChange = (event, newValue) => {
    setBottomNavigation(newValue);
  };

  const setWorkTicketComments = (comments) => {
    dispatchContext({
      type: "SET_WORKTICKET_COMMENTS",
      workticketComments: comments,
    });
  };

  const fetchWorkTicketComments = async () => {
    try {
      const response = await getWorkticketComment(props.id);
      setWorkTicketComments(response.data.data);
    } catch (e) {
      console.log("Cannot load workticket comments data");
    }
  };

  useEffect(() => {
    fetchWorkTicketComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <>
      <WorkticketBreadcrum />
      <Grid container className={classes.root}>
        {(!isMobile || (isMobile && valueBottomNavigation === "info")) && (
          <Grid item xs={12} sm={12} md={3}>
            <Box className={classes.containerMain}>
              <WorkticketInformation id_project={props.id_project} />
            </Box>
          </Grid>
        )}
        {(!isMobile || (isMobile && valueBottomNavigation === "work")) && (
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.containerBody}>
              <WorkticketHeader />
              <WorkticketGallery
                workTicketId={props.id}
                workTicketNumber={workticket?.number}
              />
              <WorkticketTask />
              <WorkticketSchedule />
              {role.category !== "subcontractor" &&
                (hasPermission(
                  permissionWorkticket.LUNA_LENS_VIEW,
                  permissions
                ) ||
                  hasPermission(
                    permissionWorkticket.LUNA_LENS_MANAGE,
                    permissions
                  )) && (
                  <UploadFileLensProvider>
                    <WorkticketLunaLens
                      workTicketId={props.id}
                      workTicketNumber={workticket?.number}
                      workTicketLocation={`${workticket?.job?.job_number} - ${workticket?.job?.job_description}`}
                      workTicketDescription={workticket?.description}
                      workTicketSummary={workticket?.subject}
                      workTicketCustomer={workticket?.job?.customer?.name}
                      workTicketType={
                        Array.isArray(workticket?.preventative_service)
                          ? workticket?.preventative_service[0]?.service_type
                          : null
                      }
                    />
                  </UploadFileLensProvider>
                )}
              <UploadFileProvider>
                <WorkticketUpload
                  workTicketId={props.id}
                  workTicketNumber={workticket?.number}
                />
              </UploadFileProvider>
              <WorkticketActivity />
            </Box>
          </Grid>
        )}
        {(!isMobile || (isMobile && valueBottomNavigation === "clock")) && (
          <Grid item xs={12} sm={12} md={3}>
            <Box className={classes.containerSidebar}>
              <WorkticketActions />
              <WorkticketRating />
              {/* <WorkticketFilePaper />
              <WorkticketFile /> */}
              <WorkticketLocation />
            </Box>
          </Grid>
        )}
      </Grid>
      {isMobile && (
        <BottomNavigation
          value={valueBottomNavigation}
          onChange={handleNavChange}
          className={classes.rootBottomNav}
          showLabels
        >
          <BottomNavigationAction value="info" icon={<InfoIcon />} />
          <BottomNavigationAction value="work" icon={<WorkIcon />} />
          <BottomNavigationAction value="clock" icon={<FileIcon />} />
        </BottomNavigation>
      )}
    </>
  );
};

export default WorkticketLayout;
