import React, { Fragment, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Typography from "@material-ui/core/Typography";
import { UploadFileProvider } from "contexts/uploadFileContext";
import { FileUploadField } from "./FileUploadField";
import { getPartnersComplianceFiles } from "services/partners/filesService";
import FilesTable from "./FilesTable";
import { usePartnerState } from "contexts/partnerContext";
import { logException } from "components/util/logUtil";
import useStyles from "../styles";
import { MultipleFileUpload } from "./MultipleFileUpload";

export function FilesPage({ id }) {
  const classes = useStyles();
  const [complianceFiles, setComplianceFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMultipleFiles, setLoadingMultipleFiles] = useState(false);
  const { partnerDetails } = usePartnerState();

  useEffect(() => {
    const loadFileData = async () => {
      setIsLoading(true);
      try {
        const result = await getPartnersComplianceFiles(id);
        setComplianceFiles(result.data.data);
      } catch (e) {
        logException(e, "Cannot load file data");
      }

      setIsLoading(false);
    };
    if (id) {
      loadFileData();
    }
  }, [id]);

  const fetchData = async () => {
    try {
      setLoadingMultipleFiles(true);
      const result = await getPartnersComplianceFiles(id);
      setComplianceFiles(result.data.data);
      setLoadingMultipleFiles(false);
    } catch (e) {
      logException(e, "Cannot load file data");
    }
  };

  if (isLoading) {
    return (
      <Box className={classes.zeroContainer}>
        <Typography className={classes.generalInformationSubHeader}>
          Compliance Files
        </Typography>
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Fragment>
      <Typography className={classes.generalInformationSubHeader}>
        Compliance Files
      </Typography>
      <Box className={classes.filesMainContainer}>
        <Box className={classes.uploadFilesMainContainer}>
          <FileUploadField
            type={1}
            isExpirationDate={false}
            label="Active Agreement"
            file={complianceFiles?.agreement}
            partnerId={id}
            fetchData={fetchData}
          />
          <MultipleFileUpload
            fetchData={fetchData}
            complianceFiles={complianceFiles?.insurance}
            id={id}
            loading={loadingMultipleFiles}
            label="Active Insurance"
          />
          <FileUploadField
            type={2}
            label="Signed NDA"
            file={complianceFiles?.nda}
            partnerId={id}
            fetchData={fetchData}
          />
          {Number(partnerDetails?.minority_owned) ? (
            <FileUploadField
              type={5}
              label="Certificate - Minority Owned (MBE)"
              file={complianceFiles?.certificate}
              partnerId={id}
              fetchData={fetchData}
            />
          ) : null}
          {Number(partnerDetails?.woman_owned) ? (
            <FileUploadField
              type={6}
              label="Certificate - Woman Owned (WBE)"
              file={complianceFiles?.certificate_woman}
              partnerId={id}
              fetchData={fetchData}
            />
          ) : null}
          {Number(partnerDetails?.disability_owned) ? (
            <FileUploadField
              type={7}
              label="Certificate - Disability Owned (DOBE)"
              file={complianceFiles?.certificate_disability}
              partnerId={id}
              fetchData={fetchData}
            />
          ) : null}
          {Number(partnerDetails?.veteran_owned) ? (
            <FileUploadField
              type={8}
              label="Certificate - Veteran Owned (VOBE)"
              file={complianceFiles?.certificate_veteran}
              partnerId={id}
              fetchData={fetchData}
            />
          ) : null}
        </Box>
      </Box>
      <Typography className={classes.generalInformationSubHeader}>
        Documents
      </Typography>
      <Box className={classes.filtersTableContainer}>
        <UploadFileProvider>
          <FilesTable id={id} />
        </UploadFileProvider>
      </Box>
    </Fragment>
  );
}
