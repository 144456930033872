import { useEffect, useRef, useState } from "react";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { AttachFileIcon } from "../icons/AttachFile";
import { FileContainer } from "./FileContainer";
import { UploadFileContainer } from "./UploadFileContainer";
import { uploadFileChunk } from "services/partners/filesService";
import useStyles from "../styles";

const CHUNK_SIZE = 5 * 1024 * 1024;

const createFormData = (
  file,
  partnerId,
  type,
  chunkIndex,
  totalChunks,
  retryCount
) => {
  const start = chunkIndex * CHUNK_SIZE;
  const end = Math.min(start + CHUNK_SIZE, file.size);
  const chunk = file.slice(start, end);

  const formData = new FormData();
  formData.append("file", chunk);
  formData.append("partnerId", partnerId);
  formData.append("fileName", file.name);
  formData.append("fileType", type);
  formData.append("chunkIndex", chunkIndex);
  formData.append("totalChunks", totalChunks);
  formData.append("retryCount", retryCount);
  return formData;
};

export const FileUploadField = ({
  label,
  file,
  partnerId,
  type,
  fetchData,
  isExpirationDate,
  hideLabel = false,
  hideUploadButton = false,
  multifleFiles = false,
  setButtonDisabled,
  isLoadingFiles,
  triggerComplete,
  setTriggerComplete,
  setIsThereAFile = null,
  setShowAddButton = null,
  setNumberOfFiles = null,
  numberOfFiles,
  index = null,
  allowUploadAfterReload = false,
}) => {
  const [fileProgress, setFileProgress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [hideContainerFileUpload, setHideContainerFileUpload] = useState(false);
  const fileInputRef = useRef(null);
  useEffect(() => {
    if (file) {
      setIsLoading(false);
    }
  }, [file]);

  const handleFileChange = async (event) => {
    if (setNumberOfFiles) {
      setNumberOfFiles();
    }
    setHideContainerFileUpload(true);
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setFileProgress({
        fileName: file.name,
        uploadProgress: 0,
        uploading: true,
        status: "In Progress",
      });
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      let currentChunk = 0;

      const uploadChunk = async (chunkIndex, retryCount = 0) => {
        const formData = createFormData(
          file,
          partnerId,
          type,
          chunkIndex,
          totalChunks,
          retryCount
        );

        try {
          if (setIsThereAFile) {
            setIsThereAFile(true);
          }
          if (isLoadingFiles) {
            isLoadingFiles(true);
          }
          if (setShowAddButton) {
            setShowAddButton(false);
          }
          const response = await uploadFileChunk(partnerId, formData);
          const uploadData = response.data.data;

          if (response.status !== 200) {
            throw new Error(
              `Chunk ${chunkIndex} failed to upload. Status: ${response.status}`
            );
          }

          if (totalChunks === 1) {
            simulateProgressForSingleChunk(file.name);
          } else {
            const progress = ((chunkIndex + 1) / totalChunks) * 100;
            updateProgress(file.name, progress);
          }

          if (uploadData.completed) {
            setFileProgress((prevState) => {
              return {
                ...prevState,
                id: uploadData.file_id,
                status: "Completed",
              };
            });
          }
        } catch (error) {
          setHideContainerFileUpload(false);
          console.error(
            `Error uploading chunk ${chunkIndex} of ${file.name}:`,
            error
          );

          if (retryCount < 3) {
            await uploadChunk(chunkIndex, retryCount + 1);
          } else {
            setFileProgress((prevState) => {
              return {
                ...prevState,
                status: "Failed",
              };
            });
            throw error;
          }
        }
      };

      while (currentChunk < totalChunks) {
        await uploadChunk(currentChunk);
        currentChunk++;
      }
    } else {
      alert("Please upload a PDF file.");
    }
  };

  const updateProgress = (fileName, progressEvent) => {
    if (progressEvent === 100) {
      if (isLoadingFiles) {
        isLoadingFiles(false);
      }
      if (setShowAddButton) {
        setShowAddButton(true);
      }
      if (setButtonDisabled) {
        setButtonDisabled(false);
      }
    }
    setFileProgress((prevState) => {
      return {
        ...prevState,
        uploadProgress: progressEvent,
      };
    });
  };

  const simulateProgressForSingleChunk = (fileName) => {
    const minProgressIncrement = 10;
    let currentProgress = 0;

    const updateProgressAndScheduleNext = () => {
      currentProgress += minProgressIncrement;
      updateProgress(fileName, currentProgress);

      if (currentProgress < 100) {
        setTimeout(updateProgressAndScheduleNext, 200);
      }
    };

    updateProgressAndScheduleNext();
  };

  const handleCompleteUpload = async () => {
    setHideContainerFileUpload(true);
    setIsLoading(true);
    await fetchData();
    setHideContainerFileUpload(false);
    setFileProgress(null);
  };

  useEffect(() => {
    if (triggerComplete) {
      handleCompleteUpload();
      setTriggerComplete(false);
    }
  }, [triggerComplete]);
  if (isLoading) {
    return (
      <Box>
        {!hideLabel && (
          <Typography className={classes.typeComplianceFileText}>
            {label}
          </Typography>
        )}
        <Box className={classes.uploadFileButtonMainContainer}>
          <LoadingIndicator />
        </Box>
      </Box>
    );
  }

  return (
    <>
      {!hideLabel && (
        <Typography className={classes.typeComplianceFileText}>
          {label}
        </Typography>
      )}
      {file ? (
        <>
          <FileContainer
            partnerId={partnerId}
            file={file}
            fetchData={fetchData}
          />
        </>
      ) : fileProgress ? (
        <UploadFileContainer
          partnerId={partnerId}
          fileState={fileProgress}
          isExpirationDate={isExpirationDate}
          handleComplete={handleCompleteUpload}
          hideUploadButton={hideUploadButton}
          setButtonDisabled={setButtonDisabled}
          isLoadingFiles={isLoadingFiles}
        />
      ) : (
        <>
          <Box className={classes.uploadFileButtonMainContainer}>
            <input
              ref={fileInputRef}
              hidden
              type={"file"}
              accept="application/pdf"
              style={{ display: "none" }}
              id={`raised-button-file-${label
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
              onChange={handleFileChange}
            />
            <label
              htmlFor={`raised-button-file-${label
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
            >
              <Box className={classes.uploadFileSquareButton}>
                <Box className={classes.uploadFileTextIconContainer}>
                  <AttachFileIcon fill="#4F98BC" />
                  <Typography className={classes.uploadFileTextSquaredButton}>
                    Upload File
                  </Typography>
                </Box>
              </Box>
            </label>
          </Box>
        </>
      )}
      {allowUploadAfterReload && numberOfFiles === index + 1 && (
        <>
          {fileProgress && (
            <UploadFileContainer
              partnerId={partnerId}
              fileState={fileProgress}
              isExpirationDate={isExpirationDate}
              handleComplete={handleCompleteUpload}
              hideUploadButton={hideUploadButton}
              setButtonDisabled={setButtonDisabled}
              isLoadingFiles={isLoadingFiles}
            />
          )}
          {!hideContainerFileUpload && (
            <Box className={classes.uploadFileButtonMainContainer}>
              <input
                ref={fileInputRef}
                hidden
                type={"file"}
                accept="application/pdf"
                style={{ display: "none" }}
                id={`raised-button-file-${label
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`}
                onChange={handleFileChange}
              />
              <label
                htmlFor={`raised-button-file-${label
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`}
              >
                <Box className={classes.uploadFileSquareButton}>
                  <Box className={classes.uploadFileTextIconContainer}>
                    <AttachFileIcon fill="#4F98BC" />
                    <Typography className={classes.uploadFileTextSquaredButton}>
                      Upload File
                    </Typography>
                  </Box>
                </Box>
              </label>
            </Box>
          )}
        </>
      )}
    </>
  );
};
