import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { SolidDocument } from "../icons/SolidDocument";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CheckCircleGreen } from "../icons/CheckCircleGreen";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import Button from "@material-ui/core/Button";
import { updatePartnerFile } from "services/partners/filesService";
import { logException } from "components/util/logUtil";
import useStyles from "../styles";

export const UploadFileContainer = ({
  partnerId,
  fileState,
  handleComplete,
  isExpirationDate = true,
  hideUploadButton = false,
  setButtonDisabled,
}) => {
  const classes = useStyles();
  const [expirationDate, setExpirationDate] = useState(null);

  const handleChangeExpirationDate = async (date) => {
    try {
      setExpirationDate(date);
      await updatePartnerFile(partnerId, fileState.id, {
        expiration_date: date,
      });
    } catch (error) {
      logException(error, "Error updating file expiration date");
    }
  };
  useEffect(() => {
    if (setButtonDisabled) {
      setButtonDisabled(true);
    }
  }, []);
  const labelText = isExpirationDate
    ? "Select Expiration Date(*)"
    : "Select Expiration Date";
  return (
    <Box className={classes.uploadingFileDottedContainer}>
      <Box className={classes.fileNameAndActionsBar}>
        <Box className={classes.documentIconAndNameContainer}>
          <SolidDocument />
          <Box className={classes.nameAndProgressBarContainer}>
            <Typography className={classes.documentNameText}>
              {fileState.fileName}
            </Typography>
            {fileState.uploading && (
              <Box width="100%" mr={1}>
                <LinearProgress
                  value={fileState.uploadProgress}
                  color="secondary"
                  variant="determinate"
                  className={classes.progressBar}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.checkDeleteContainer}>
          {fileState.uploadProgress === 100 ? (
            <CheckCircleGreen />
          ) : (
            <Box className={classes.emptyCircle}></Box>
          )}
        </Box>
      </Box>
      <Box className={classes.selectExpirationDateContainer}>
        <Typography className={classes.selectExpirationDateText}>
          {labelText}
        </Typography>
        <Box className={classes.expirationDateFieldContainer}>
          <FormInputDateAdo
            name="expiration_date"
            handleChange={handleChangeExpirationDate}
            value={expirationDate}
            disabled={!fileState?.id}
          />
        </Box>
        {!hideUploadButton && (
          <Button
            className={
              !isExpirationDate
                ? classes.finishUploadButton
                : fileState?.id && expirationDate
                ? classes.finishUploadButton
                : classes.finishUploadButtonDisabled
            }
            onClick={handleComplete}
            disabled={
              (isExpirationDate
                ? !fileState?.id || !expirationDate
                : !fileState?.id) || fileState.uploadProgress !== 100
            }
          >
            {fileState?.id && fileState.uploadProgress === 100
              ? "Finish Upload"
              : "Uploading..."}
          </Button>
        )}
      </Box>
    </Box>
  );
};
