import http from "./httpService";
import { apiUrl } from "../lib/config";
import { setFormData } from "../components/util/serviceUtil";
import { getFilterString } from "components/util/filterUtil";
const apiEndpoint = `${apiUrl}/customers`;

export function getCustomers() {
  return http.get(`${apiEndpoint}/list`);
}

export function getCustomerMetadata(id) {
  return http.get(`${apiEndpoint}/${id}/metadata`);
}

export function getCustomerDetail(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function getCustomerContact(id) {
  return http.get(`${apiEndpoint}/${id}/contacts`);
}

export function getCustomerContactDetail(id, id_contact, type) {
  return http.get(`${apiEndpoint}/${id}/contacts/${id_contact}/${type}`);
}

export function getCustomerUserContact(id) {
  return http.get(`${apiEndpoint}/${id}/user-contacts`);
}

export function getCustomerJobDetail(id, id_job) {
  return http.get(`${apiEndpoint}/${id}/jobs/${id_job}`);
}

export function assignCustomerJobUser(id, id_job, data) {
  return http.post(`${apiEndpoint}/${id}/jobs/${id_job}/users`, data);
}

export function removeCustomerJobUser(id, id_job, user_id) {
  return http.delete(`${apiEndpoint}/${id}/jobs/${id_job}/users/${user_id}`);
}

export function createCustomerDetail(data) {
  return http.post(`${apiEndpoint}`, data);
}

export function saveCustomerFile(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/upload-files`, setFormData(data));
}

export function updateCustomerDetail(id, data) {
  return http.post(`${apiEndpoint}/${id}/update`, data);
}

export function saveCustomerContact(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/contact/add`, setFormData(data));
}

export function deleteCustomerContact(id, id_contact, type) {
  return http.delete(`${apiEndpoint}/${id}/contacts/${id_contact}/${type}`);
}

export function updateCustomerContact(id, id_contact, type, data) {
  http.setMultiPart();
  return http.post(
    `${apiEndpoint}/${id}/contacts/${id_contact}/${type}`,
    setFormData(data)
  );
}

export function getCustomerJobs(id) {
  return http.get(`${apiEndpoint}/${id}/jobs`);
}

export function getCustomerJobsManage(id) {
  return http.get(`${apiEndpoint}/${id}/jobs-manage`);
}

export function getCustomerJobsAvailable() {
  return http.get(`${apiEndpoint}/jobs-available`);
}

export function updateCustomerJob(id, id_job, data) {
  return http.put(`${apiEndpoint}/${id}/jobs/${id_job}`, data);
}

export function assignCustomerJobs(id, data) {
  return http.post(`${apiEndpoint}/${id}/assign-jobs`, data);
}

export function removeCustomerJobs(id, data) {
  return http.delete(`${apiEndpoint}/${id}/assign-jobs`, {
    data: { ...data },
  });
}

export const addChildrenJob = (job, data) => {
  return http.post(`${apiEndpoint}/jobs/${job}/add-children`, data);
};

export const removeChildrenJob = (job, data) => {
  return http.post(`${apiEndpoint}/jobs/${job}/remove-children`, data);
};

export const getChildrenJob = (job) => {
  return http.get(`${apiEndpoint}/jobs/${job}/children`);
};

export const getCustomersFilter = () => {
  return http.get(`${apiUrl}/filters/user-customers`);
};
export function getCustomersInformation(filterData = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/index?${filterString}`, {});
}

export function getCustomersInformationSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/index?${filterString}search=${search}`, {});
}

export function getCustomerParents(customer, page, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${customer}/parents?${filterString}page=${page ?? 0}`
  );
}

export function getCustomerChildrenJob(job) {
  return http.get(`${apiEndpoint}/${job}/children`);
}

export function getActivities(id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/activity?${filterString}`);
}

export function getActivitiesSearch(id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/activity?${filterString}search=${search}`,
    {}
  );
}

export function getActivitiesOffset(id, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/activity?${filterString}offset=${offset}`
  );
}

export function getCustomerParentsSearch(customer, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${customer}/parents?${filterString}search=${search}`
  );
}

export function getRepairInfo() {
  return http.get(`${apiEndpoint}/repair-categories`);
}

export function getRepairApplyCustomers() {
  return http.get(`${apiEndpoint}/apply-repair-categories`);
}
