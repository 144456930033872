import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RateReviewIcon from "@material-ui/icons/RateReview";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import useStyles from "../../partnersList/dialog/styles";
import { useComments } from "./useComments";

export const CommentDialogue = () => {
  const classes = useStyles();
  const {
    comment,
    dispatchCloseDialog,
    handleSubmitComment,
    isCommentsLoading,
    isMutationLoading,
    isOpenAddDialog,
    setComment,
    showLoadingDialog,
  } = useComments();

  const loading =
    !isOpenAddDialog &&
    showLoadingDialog &&
    (isCommentsLoading || isMutationLoading);

  return (
    <>
      <Dialog
        open={loading}
        aria-labelledby="loading-dialog"
        maxWidth="xs"
        fullWidth
      >
        <Box>
          <LoadingIndicator />
        </Box>
      </Dialog>

      <Dialog
        open={isOpenAddDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={dispatchCloseDialog}
          className={classes.wrapperDialogClose}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>
        <DialogTitle className={classes.wrapperDialog}>
          <Box className={classes.titleWithIcon}>
            <RateReviewIcon className={classes.rateIcon} />
            <Typography
              className={classes.titleDialog}
              variant="caption"
              gutterBottom
            >
              {"Edit Comment"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.selectContainer}>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="feedback" className={classes.feedbackLabel}>
                Comment
              </FormLabel>
              <TextField
                id="feedback"
                size="small"
                variant="standard"
                multiline
                minRows={5}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                InputProps={{ disableUnderline: true }}
                className={classes.textArea}
                style={{ marginTop: "4px" }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button
            onClick={dispatchCloseDialog}
            className={classes.buttonCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmitComment}
            className={classes.buttonConfirm}
          >
            {"Update Comment"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
