import { createContext, useContext, useReducer } from "react";
import * as partnerService from "../services/partnerService";
import { getAiSearch } from "../services/aiServices";
import { merge } from "lodash";

const defaultState = {
  partnerListLoading: true,
  partnerSelected: null,
  partnerList: [],
  partnerCount: [],
  partnerDetails: null,
};

const PartnerStateContext = createContext();
const PartnerDispatchContext = createContext();

function projectReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        partnerListLoading: action.partnerListLoading,
      };
    case "SET_PARTNER_LIST":
      return {
        ...state,
        partnerList: action.partnerList,
      };
    case "SET_PARTNER_COUNT":
      return {
        ...state,
        partnerCount: action.partnerCount,
      };
    case "SET_PARTNER_SELECTED":
      return {
        ...state,
        partnerSelected: action.partnerSelected,
      };
    case "SET_PARTNER_DETAILS":
      return {
        ...state,
        partnerDetails: action.partnerDetails,
      };
    case "UPDATE_PARTNER_STATUS":
      const { partnerId, statusId } = action.payload;
      const updatedPartnerList = state.partnerList.map((partner) =>
        partner.id === partnerId ? { ...partner, status: statusId } : partner
      );
      return {
        ...state,
        partnerList: updatedPartnerList,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function PartnerProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(projectReducer, mergedState);
  return (
    <PartnerStateContext.Provider value={state}>
      <PartnerDispatchContext.Provider value={dispatch}>
        {children}
      </PartnerDispatchContext.Provider>
    </PartnerStateContext.Provider>
  );
}

function usePartnerState() {
  const context = useContext(PartnerStateContext);
  if (context === undefined) {
    throw new Error("usePartnerState must be used within a PartnerProvider");
  }
  return context;
}

function usePartnerDispatch() {
  const context = useContext(PartnerDispatchContext);
  if (context === undefined) {
    throw new Error("usePartnerDispatch must be used within a PartnerProvider");
  }
  return context;
}

async function getPartner(dispatch, id) {
  try {
    //const partnerDetailsResult = await partnerService.getProjectDetails(id);
    dispatch({
      type: "SET_PARTNER_DETAILS",
      partnerDetails: [],
    });
  } catch (error) {
    console.log(error);
  }
}

async function getPartnerList(dispatch, filterData, tab) {
  try {
    const response = await partnerService.getPartners(filterData, tab);
    dispatch({
      type: "SET_PARTNER_LIST",
      partnerList: response.data.data.partners,
    });
    dispatch({
      type: "SET_PARTNER_COUNT",
      partnerCount: response.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      partnerListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function setPartnerList(dispatch, data) {
  try {
    const response = data;
    dispatch({
      type: "SET_PARTNER_LIST",
      partnerList: response.data.partners ?? [],
    });
    dispatch({
      type: "SET_PARTNER_COUNT",
      partnerCount: response.data.filter_counts ?? 0,
    });
    dispatch({
      type: "SET_LOADING",
      partnerListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getPartnerListDataSet(
  partnerList,
  dispatch,
  tab,
  offset,
  filterData,
  filterAddon = undefined
) {
  try {
    const result = await partnerService.getPartnersTabOffset(
      tab,
      offset,
      filterData,
      filterAddon
    );
    const updatePartnerList = [...partnerList, ...result.data.data.partners];
    // const updatePartnerList = [...result.data.data.partners];
    dispatch({
      type: "SET_PARTNER_LIST",
      partnerList: updatePartnerList,
    });

    return true;
  } catch (error) {
    console.log(error);
  }
}

async function searchAiPartners(userEmail, userId, search) {
  try {
    const partnerListResult = await getAiSearch(userEmail, userId, search);
    return partnerListResult.data.data;
  } catch (error) {
    console.log(error);
  }
}

export {
  PartnerProvider,
  usePartnerState,
  usePartnerDispatch,
  getPartner,
  getPartnerList,
  searchAiPartners,
  getPartnerListDataSet,
  setPartnerList,
};
