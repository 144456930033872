import React, { useState, useContext } from "react";
import GlobalUiContext from "contexts/globalUiContext";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import { dateFormatField } from "../../../components/util/timeFormat";
import { useComments } from "./useComments";
import { permissionAdmin, hasPermission } from "lib/permissions";

export function CommentItem({ classes, comment, index }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const {
    isCommentsLoading,
    isMutationLoading,
    deleteComment,
    dispatchEditComment,
    userId,
  } = useComments();

  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenConfirm = (commentToDelete) => {
    setCommentToDelete(commentToDelete);
    handleClose();
    setIsConfirmDialogOpen(true);
  };

  const handleCloseConfirm = () => setIsConfirmDialogOpen(false);

  const handleDeleteComment = () => {
    if (commentToDelete && deleteComment && deleteComment.mutate) {
      setIsDeletingInProgress(true);
      deleteComment.mutate({
        id: commentToDelete.id,
        partner_id: userId,
      });
      setIsDeletingInProgress(false);
      setIsConfirmDialogOpen(false);
    } else {
      console.error("deleteComment or mutate function is not available.");
    }
  };

  return (
    <Box
      key={index}
      className={`${classes.commentContainer} ${
        userId === comment.user_id ? classes.commentContainerActive : ""
      }`}
    >
      <Box className={classes.commentsTopContainer}>
        <Typography className={classes.commentNameText}>
          {comment.user_name}
        </Typography>
        <Box className={classes.commentDateMenuContainer}>
          {dateFormatField(comment.updated_at)}
          {hasPermission(permissionAdmin.COMMENTS_MANAGE, permissions) &&
            user.id === parseInt(comment.user_id) && (
              <Box
                className={classes.threeDotsContainerComments}
                onClick={handleClick}
              >
                <MoreHorizIcon fontSize="small" />
              </Box>
            )}
        </Box>
      </Box>

      <Box className={classes.commentsContainer}>{comment.body}</Box>

      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disabled={isMutationLoading}
          onClick={() => {
            dispatchEditComment(comment);
            handleClose();
          }}
        >
          <Box className={classes.menuIcon}>
            <EditIcon fontSize="inherit" />
          </Box>
          <Typography variant="caption" className={classes.menuText}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem
          disabled={isMutationLoading}
          onClick={() => handleOpenConfirm(comment)}
        >
          <Box className={classes.menuIcon}>
            <DeleteIcon fontSize="inherit" />
          </Box>
          <Typography variant="caption" className={classes.menuText}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        handleConfirm={handleDeleteComment}
        handleClose={handleCloseConfirm}
        message={"Are you sure you want to delete this comment?"}
        title={"Comments"}
      />

      <Dialog
        open={isDeletingInProgress || isMutationLoading || isCommentsLoading}
        aria-labelledby="loading-dialog"
        maxWidth="xs"
        fullWidth
      >
        <Box>
          <LoadingIndicator />
        </Box>
      </Dialog>
    </Box>
  );
}
