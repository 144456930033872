import { FileUploadField } from "./FileUploadField";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import FormSelectAuto from "../../../components/ui/FormContent/formSelectAuto";
import Box from "@material-ui/core/Box";
import { AttachFileIcon } from "../icons/AttachFile";
import LoadingIndicator from "../../../components/common/LoadingIndicator/loadingIndicator";
export function MultipleFileUpload({
  complianceFiles,
  id,
  fetchData,
  label,
  loading,
}) {
  const classes = useStyles();
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const defaultData = {
    number_of_files: [],
  };
  const [triggerComplete, setTriggerComplete] = useState(false);
  const [data, setData] = useState(defaultData);
  const [isThereAFile, setIsThereAFile] = useState(false);
  const optionsNumberOfFiles = [...Array(6).keys()].map((item) => {
    return { value: item + 1, label: String(item + 1) };
  });
  const [numberOfFiles, setNumberOfFiles] = useState(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showAddButton, setShowAddButton] = useState(false);
  const handleChangeNumberOfFiles = (event, value) => {
    const newData = {
      ...data,
      number_of_files: value ?? [],
    };
    setData(newData);
  };
  function addMoreFiles() {
    setNumberOfFiles((prev) => prev + 1);
  }
  return (
    <>
      <Typography className={classes.typeComplianceFileText}>
        {label}
      </Typography>
      {loading && <LoadingIndicator />}
      {!loading && (
        <>
          {!complianceFiles?.length > 0 && (
            <>
              {Array.from({ length: numberOfFiles }, (_, i) => (
                <FileUploadField
                  index={i}
                  isExpirationDate={false}
                  hideUploadButton={true}
                  hideLabel={true}
                  type={3}
                  label="Active Insurance"
                  file={
                    Array.isArray(complianceFiles) && complianceFiles[i]
                      ? complianceFiles[i]
                      : null
                  }
                  partnerId={id}
                  setButtonDisabled={(value) => setIsButtonDisabled(value)}
                  isLoadingFiles={(value) => setIsLoadingFiles(value)}
                  fetchData={fetchData}
                  setIsThereAFile={(value) => setIsThereAFile(value)}
                  triggerComplete={triggerComplete}
                  setTriggerComplete={(value) => setTriggerComplete(value)}
                  setShowAddButton={(value) => setShowAddButton(value)}
                  multifleFiles={true}
                  numberOfFiles={numberOfFiles}
                  setNumberOfFiles={addMoreFiles}
                />
              ))}
              {isThereAFile && (
                <>
                  <Box className={classes.buttonUploadMultiple}>
                    <Button
                      className={
                        !isButtonDisabled
                          ? classes.finishUploadButton
                          : classes.finishUploadButtonDisabled
                      }
                      onClick={() => setTriggerComplete(true)}
                      disabled={isButtonDisabled}
                    >
                      {!isLoadingFiles ? "Finish Upload" : "Uploading..."}
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
          {complianceFiles?.length > 0 &&
            complianceFiles.map((file, index) => (
              <>
                <FileUploadField
                  hideUploadButton={false}
                  hideLabel={true}
                  type={3}
                  label="Active Insurance"
                  file={file}
                  partnerId={id}
                  isExpirationDate={false}
                  fetchData={fetchData}
                  allowUploadAfterReload={true}
                  numberOfFiles={complianceFiles.length}
                  index={index}
                />
              </>
            ))}
        </>
      )}
    </>
  );
}
